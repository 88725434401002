<template>
<v-dialog v-model="searchDialog" width="100%" overlay-opacity="0.95" content-class="elevation-0">
    <template v-slot:activator="{ on }">
        <slot name="button" v-bind:on="on">
            <v-btn text v-on="on" title="Search"><v-icon>{{ mdiMagnify }}</v-icon></v-btn>
        </slot>
    </template>
    <v-container>
        <v-form @submit.prevent="search()">
            <v-row justify="center" align="center" dense>
                <v-col cols="8" sm="9">
                    <v-text-field
                        v-model="searchTerm" 
                        height="50px" 
                        id="search" 
                        dark 
                        color="secondary" 
                        :class="`${getTextSize()} secondary--text font-weight-bold py-5`" 
                        placeholder="Type to search"
                        hint = "Begin typing your search above and press return to search."
                        persistent-hint
                    />
                </v-col>
                <v-col cols="2" sm="1">
                    <v-btn color="secondary" fab text type="submit" title="Perform Search"><v-icon x-large>{{ mdiMagnify }}</v-icon></v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</v-dialog>
</template>

<script>
import { mdiMagnify } from "@mdi/js";

export default {
    name: "Search",

    data() {
        return {
            mdiMagnify: mdiMagnify,
            searchDialog: false,
            searchTerm: ""
        };
    },

    methods: {
        async search() {
            this.searchDialog = false; 
            this.$router.push({ name: 'travel', query: { query: this.searchTerm }});  
        },

        getTextSize() {
            return this.$vuetify.breakpoint.xsOnly ? "title" : "display-1";
        },
    }
};
</script>

<style lang="scss" scoped>
#search, #search::placeholder {
    color: $secondary_color !important;
}

.v-messages__message {
    color: $secondary_color !important;
    opacity: 0.6;
}

#search {
    max-height: 50px;
}
</style>
